import React from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetSetData } from "../../services/useGetSetHook";

const DispenseBreakdown = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } =
    useGetSetData(
      process.env.REACT_APP_BASE_API + "/dispense_breakdown",
      false
    );

  const calculateQuarterlyData = (data) => {
    const quarterData = {};
    data.forEach((item) => {
      const date = new Date(item.Date);
      const quarter =
        "Q" + Math.ceil((date.getMonth() + 1) / 3) + " " + date.getFullYear();
      if (!quarterData[quarter]) {
        quarterData[quarter] = 0;
      }
      quarterData[quarter] += parseInt(item.rx_records.replace(/,/g, ""));
    });
    return quarterData;
  };

  const quarterData = calculateQuarterlyData(filteredData);
  const categories = Object.keys(quarterData);
  const groupedCategories = [];
  let currentYear = "";
  categories.forEach((category) => {
    const year = category.split(" ")[1];
    if (year !== currentYear) {
      if (currentYear !== "") {
        // Add a gap between years
        groupedCategories.push("null");
      }
      currentYear = year;
    }
    groupedCategories.push(category);
  });
  const seriesData = groupedCategories.map((category) => {
    if (category === "null") {
      return "null"; // Return null for gap between years
    } else {
      return quarterData[category]; // Retrieve data value for each category
    }
  });

  const chartOptions = {
    chart: {
      type: "line",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Dispenses Submitted to the PDMP",
    },
    xAxis: {
      title: {
        text: "Submitted Dispenses",
      },

      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Dispenses",
      },
    },

    series: [
      {
        name: "Records",
        data: filteredData.map((data) =>
          parseInt(data.rx_records.replace(/,/g, ""))
        ),
      },
    ],
    tooltip: {
      formatter: function () {
        return `<span style="font-size: 10px">${this.x}</span><br/>
                  <span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${Highcharts.numberFormat(this.y, 0, ".", ",")}</b>`;
      },
    },
  };

  const chartOptions4 = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<span style="font-size: 10px">${this.x}</span><br/>
                  <span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${Highcharts.numberFormat(this.y, 0, ".", ",")}</b>`;
      },
    },
    title: {
      text: "Column Chart with Quarters",
    },
    xAxis: {
      categories: groupedCategories,
      reversed: true,
      labels: {
        formatter: function () {
          if (this.value === "null") {
            return ""; // Return empty string for null categories
          } else {
            return this.value; // Show the category value for other categories
          }
        },
      },
    },

    yAxis: {
      title: {
        text: "RxRecords",
      },
    },
    series: [
      {
        name: "RxRecords",
        data: seriesData,
      },
    ],
  };
  const tableConfigs = [
    {
      title: "Rx Data",
      columns: [
        "Month",
        "Monthly Reported Dispenses",
        "Controlled Substance",
        "C2",
        "C3",
        "C4",
        "C5",
        "Non-Controlled Substances",
        "Unknown",
        "Cumulative Records",
      ],
      dataKeys: [
        "Date",
        "rx_records",
        "rx_controlled_substance",
        "rx_c2",
        "rx_c3",
        "rx_c4",
        "rx_c5",
        "rx_noncontrolled",
        "rx_unknown",
        "cumulative_records",
      ],
    },
  ];

  const renderTable = (config) => (
    <Grid item>
      <TableContainer component={Paper} style={{ width: "88vw", border: ".5px solid lightgrey", marginBottom:'10px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell sx={{ textAlign: "center" }} key={index}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => (
                  <TableCell sx={{ textAlign: "center" }} key={index}>
                    {" "}
                    {key === "Date" ? format(data[key], "MMM-yy") : data[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
          {datePickersComponent}
        </Grid>
        <Grid item lg={1}>
          {disclaimerComponent("dispenseBreakdown")}
        </Grid>
      </Grid>
      <Paper style={{ marginBottom: "15px", padding: "10px" }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Paper>

      <Grid container spacing={2}>
        {tableConfigs.map((config, index) => (
          <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
        ))}
      </Grid>
      <Paper style={{ marginBottom: "15px", padding: "10px" }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions4} />
      </Paper>
    </>
  );
};

export default DispenseBreakdown;
