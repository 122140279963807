import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { format } from "date-fns";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useGetSetData } from "../../services/useGetSetHook";

const DataMetric = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } =
    useGetSetData(process.env.REACT_APP_BASE_API + "/data_metrics", false);

  const columns = Object.values(
    filteredData.map((x) => format(x.Date, "MMM-yy"))
  );

  const tableConfig1 = [
    {
      columns: columns,
      dataKeys: [
        "pay_type_pp, pay_type_pp_percent",
        "pay_type_medicaid, pay_type_medicaid_percent",
        "pay_type_medicare, pay_type_medicare_percent",
        "pay_type_ci, pay_type_ci_percent",
        "pay_type_va, pay_type_va_percent",
        "pay_type_wc, pay_type_wc_percent",
        "pay_type_other, pay_type_other_percent",
        "pay_type_in, pay_type_in_percent",
        "pay_type_total",
      ],
      labels: [
        "Private Pay (%)",

        "Medicaid (%)",

        "Medicare (%)",

        "Commercial Insurance (%)",

        "Veteran's Administration / Military (%)",

        "Worker's Compensation (%)",

        "Other Payment Sources (%)",

        "Indian Health Services (%)",

        "Total",
      ],
    },
  ];

  const tableConfig2 = [
    {
      columns: columns,
      dataKeys: [
        "pat_id_mil, pat_id_mil_percent",
        "pat_id_state, pat_id_state_percent",
        "pat_id_unique, pat_id_unique_percent",
        "pat_id_rescard, pat_id_rescard_percent",
        "pat_id_passport, pat_id_passport_percent",
        "pat_id_dl, pat_id_dl_percent",
        "pat_id_ssn, pat_id_ssn_percent",
        "pat_id_tribal, pat_id_tribal_percent",
        "pat_id_other, pat_id_other_percent",
        "pat_id_absent, pat_id_absent_percent",
        "pat_id_total",
      ],

      labels: [
        "Military ID (%)",

        "State ID (not driver’s license) (%)",

        "System-generated Unique ID (%)",

        "Permanent Resident Card (%)",

        "Passport (%)",

        "Driver's License (%)",

        "Social Security Number (%)",

        "Tribal ID (%)",

        "Other (%)",

        "Absent (%)",

        "Total",
      ],
    },
  ];
  const tableConfig3 = [
    {
      columns: columns,
      dataKeys: [
        "rx_trans_type_wp, rx_trans_type_wp_percent",
        "rx_trans_type_tp, rx_trans_type_tp_percent",
        "rx_trans_type_tep, rx_trans_type_tep_percent",
        "rx_trans_type_fp, rx_trans_type_fp_percent",
        "rx_trans_type_ep, rx_trans_type_ep_percent",
        "rx_trans_type_transfer, rx_trans_type_transfer_percent",
        "rx_trans_type_other, rx_trans_type_other_percent",
        "rx_trans_type_absent, rx_trans_type_absent_percent",
        "rx_trans_type_total",
      ],
      labels: [
        "Written Prescription (%)",

        "Telephone Prescription (%)",

        "Telephone Emergency Prescription (%)",

        "Faxed Prescription (%)",

        "Electronic Prescription (%)",

        "Via Transfer (%)",

        "Other (%)",

        "Absent (%)",

        "Total",
      ],
    },
    {
      subHeading: "Controlled Substance Transmission Type",
      columns: columns,
      dataKeys: [
        "rx_trans_cs_wp, rx_trans_cs_wp_percent",
        "rx_trans_cs_tp, rx_trans_cs_tp_percent",
        "rx_trans_cs_tep, rx_trans_cs_tep_percent",
        "rx_trans_cs_fp, rx_trans_cs_fp_percent",
        "rx_trans_cs_ep, rx_trans_cs_ep_percent",
        "rx_trans_cs_transfer, rx_trans_cs_transfer_percent",
        "rx_trans_cs_other, rx_trans_cs_other_percent",
        "rx_trans_cs_absent, rx_trans_cs_absent_percent",
        "rx_trans_cs_total",
      ],
      labels: [
        "Written Prescription (%)",
        "Telephone Prescription (%)",
        "Telephone Emergency Prescription (%)",
        "Faxed Prescription (%)",
        "Electronic Prescription (%)",
        "Via Transfer (%)",
        "Other (%)",
        "Absent (%)",
        "Total",
      ],
    },
  ];

  const tableConfig5 = [
    {
      subHeading: "Contains Date Sold",
      columns: columns,
      dataKeys: [
        "date_sold_present, date_sold_present_percent",
        "date_sold_absent, date_sold_absent_percent",
        "date_sold_total",
      ],
      labels: ["Yes (%)", "No (%)", "Total"],
    },
    {
      subHeading: "Dispense Breakdown by Species",
      columns: columns,
      dataKeys: [
        "species_human, species_human_percent",
        "species_vet, species_vet_percent",
        "species_total",
      ],
      labels: ["Human Dispenses (%)", "Veterinarian Dispenses (%)", "Total"],
    },
    {
      subHeading: "Has Phone Number?",
      columns: columns,
      dataKeys: [
        "has_phone_yes, has_phone_yes_percent",
        "has_phone_no, has_phone_no_percent",
        "has_phone_total",
      ],
      labels: ["Yes (%)", "No (%)", "Total"],
    },
    {
      subHeading: "Reported Gender",
      columns: columns,
      dataKeys: [
        "pat_gender_m, pat_gender_m_percent",
        "pat_gender_f, pat_gender_f_percent",
        "pat_gender_u, pat_gender_u_percent",
        "pat_gender_absent, pat_gender_absent_percent",
        "pat_gender_total",
      ],
      labels: ["Male (%)", "Female (%)", "Unknown (%)", "Absent (%)", "Total"],
    },
    {
      subHeading: "Includes Prescription Directions",
      columns: columns,
      dataKeys: [
        "rx_sig_yes, rx_sig_yes_percent",
        "rx_sig_no, rx_sig_no_percent",
        "rx_sig_total",
      ],
      labels: ["Yes (%)", "No (%)", "Total"],
    },
    {
      subHeading: "Contains Opioid Treatment Type Information",
      columns: columns,
      dataKeys: [
        "treatment_type_yes, treatment_type_yes_percent",
        "treatment_type_no, treatment_type_no_percent",
        "treatment_type_total",
      ],
      labels: ["Yes (%)", "No (%)", "Total"],
    },
    {
      subHeading: "Contains Diagnosis Code",
      columns: columns,
      dataKeys: [
        "dx_code_yes, dx_code_yes_percent",
        "dx_code_no, dx_code_no_percent",
        "dx_code_total",
      ],
      labels: ["Yes (%)", "No (%)", "Total"],
    },
  ];

  const renderTable = (config) => (
    <Grid item>
      <Typography textAlign="center" variant="h6" sx={{ marginTop: "10px" }}>
        {" "}
        {config.subHeading}
      </Typography>
      <TableContainer component={Paper} style={{ width: "100%", border: ".5px solid lightgrey" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{
                  borderRight: "1px solid lightgray", // Add a right border to the TableCell
                  "&:last-child": {
                    borderRight: "none", // Remove the border from the last TableCell
                  },
                }}
              ></TableCell>
              {config.columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {config.dataKeys.map((metric, index) =>
              metric === "Date" ? null : (
                <TableRow key={metric}>
                  <TableCell
                    colSpan={6}
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    {}
                    <Typography fontWeight="bold">
                      {config.labels[index]}
                    </Typography>{" "}
                    {/* Metric name in the first column */}
                  </TableCell>
                  {filteredData.map((item) => (
                    <TableCell key={item.Date} sx={{ fontSize: "13px" }}>
                      {" "}
                      {metric
                        .split(", ")
                        .map((key, i) =>
                          i === 0 ? item[key] : ` (${item[key]})`
                        )}
                    </TableCell> // Render values for each metric
                  ))}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
          {datePickersComponent}
        </Grid>
        <Grid item lg={1}>
          {disclaimerComponent("dataMetrics")}
        </Grid>
      </Grid>
      <Accordion sx={{ width: "88vw" }}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">Reported Insurance Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {tableConfig1.map((config, index) => (
            <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ width: "88vw" }}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">
            Reported Primary Patient Identifier
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {tableConfig2.map((config, index) => (
            <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ width: "88vw" }}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">Prescription Transmission Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {tableConfig3.map((config, index) => (
            <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ width: "88vw" }}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">Data Metrics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {tableConfig5.map((config, index) => (
            <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DataMetric;
