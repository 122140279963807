import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetSetData } from "../../services/useGetSetHook";

const MpeAlerts = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } = useGetSetData(
    process.env.REACT_APP_BASE_API + "/mpe_alerts",
    false
  );

  const chartOptions = {
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "MPE: Multiple Provider Alert ",
    },
    xAxis: {
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true
    },
    yAxis: {
      min: 0,
      title: {
        text: "Patient Count Using",
      },
      stackLabels: {
        enabled: true,
      },
    },
    plotOptions: {
      series: {
    
 
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Prescribers (1-5)",
        data: filteredData.map((data) => parseInt(data.mpe_prescribers15.replace(/,/g, ""))),
      },

      {
        name: "Pharmacies (1-5)",
        data: filteredData.map((data) => parseInt(data.mpe_pharmacies15.replace(/,/g, ""))),
      },
    ],
  };
  const chartOptions2 = {
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "MPE: Multiple Provider Alert – Use of 6+ Providers and/or Pharmacies",
    },
    xAxis: {
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true
    },
    yAxis: {
      min: 0,
      tickInterval: 50,
      title: {
        text: "Patient Count Using",
      },
      stackLabels: {
        enabled: true,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Prescribers (> 10)",
        data: filteredData.map((data) => parseInt(data.mpe_prescribersgt10.replace(/,/g, ""))),
      },
      {
        name: "Pharmacies (6-10)",
        data: filteredData.map((data) => parseInt(data.mpe_pharmacies610.replace(/,/g, ""))),
      },

      {
        name: "Pharmacies (> 10)",
        data: filteredData.map((data) => parseInt(data.mpe_pharmaciesgt10.replace(/,/g, ""))),
      },
      {
        name: "Prescribers (6-10)",
        data: filteredData.map((data) => parseInt(data.mpe_prescribers610.replace(/,/g, ""))),
      },
    ],
  };

  const chartOptions3 = {
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "MPE – Patient Using Prescriber Drill Down to 1 – 2 vs 3 – 5 Providers",
    },
    xAxis: {
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true
    },
    yAxis: {
      min: 0,

      title: {
        text: "Patient Count Using",
      },
      stackLabels: {
        enabled: true,
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Prescribers (3-5)",
        data: filteredData.map((data) => parseInt(data.mpe_prescribers35.replace(/,/g, ""))),
      },
      {
        name: "Prescribers (1-2)",
        data: filteredData.map((data) => parseInt(data.mpe_prescribers12.replace(/,/g, ""))),
      },
    ],
  };
  const tableConfigs = [
    {
      title: "MPE Alerts",
      columns: [
        "Month",
        "Number of Alerts",
        "Unique Patients",
        "Patients with dispenses from 1 – 5 Prescribers",
        "1-2 Prescribers",
        "3-5 Prescribers",
        "Patients with dispenses from 6 – 10 Prescribers",
        "Patients with dispenses from > 10 Prescribers",
        "Patients utilizing 1 – 5 different Pharmacies)",
        "Patients utilizing 6 - 10 different Pharmacies)",
        "Patients utilizing > 10 different Pharmacies",
        "Patients with dispenses from 1 – 5 Prescribers AND utilizing 1 – 5 different pharmacies",
        "Patients with dispenses from 6 - 10 Prescribers AND utilizing 6 - 10 different pharmacies",
        "Patients with dispenses from > 10 Prescribers AND utilizing > 10 different pharmacies",
      ],
      dataKeys: [
        "Date",
        "mpe_alerts",
        "mpe_uniquepatients",
        "mpe_prescribers15",
        "mpe_prescribers12",
        "mpe_prescribers35",
        "mpe_prescribers610",
        "mpe_prescribersgt10",
        "mpe_pharmacies15",
        "mpe_pharmacies610",
        "mpe_pharmaciesgt10",
        "mpe_both15",
        "mpe_both610",
        "mpe_bothgt10",
      ],
    },
  ];

  const renderTable = (config) => (
    <Grid item>
      <TableContainer component={Paper} style={{ width: "88vw", border: ".5px solid lightgrey"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => (
                  <TableCell key={index}>
                    {" "}
                    {key === "Date" ? format(data[key], "MMM-yy") : data[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
        {datePickersComponent}
        </Grid>
        <Grid item >
        {disclaimerComponent("mpeAlerts")}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions3} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions2} />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {tableConfigs.map((config, index) => (
          <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
        ))}
      </Grid>
    </>
  );
};

export default MpeAlerts;
