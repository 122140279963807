import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Divider,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useGetSetData } from "../../services/useGetSetHook";

const UserQueries = () => {
  const { filteredData, datePickersComponent, data, disclaimerComponent } =
    useGetSetData(process.env.REACT_APP_BASE_API + "/user_queries", false);

  const pieChartOptions = {
    chart: {
      type: "pie",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    title: {
      text: `User Queries by Prescriber Type ${format(
        data[data.length - 1]?.Date || Date.now(),
        "MMM-yy"
      )}`,
    },
    series: [
      {
        name: "User Queries",
        data: [
          {
            name: "Physician",
            y: parseInt(data[data.length - 1]?.nepq_md.replace(/,/g, "")),
          },
          {
            name: "PA",
            y: parseInt(data[data.length - 1]?.nepq_pa.replace(/,/g, "")),
          },
          {
            name: "APRN",
            y: parseInt(data[data.length - 1]?.nepq_aprn.replace(/,/g, "")),
          },
          {
            name: "DDS",
            y: parseInt(data[data.length - 1]?.nepq_dds.replace(/,/g, "")),
          },
        ],
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}%",
          distance: -50,
          connectorShape: "straight",
        },
      },
    ],
  };
  const pieChartOptions2 = {
    chart: {
      type: "pie",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `Unique User Queries by License Type ${format(
        data[data.length - 1]?.Date || Date.now(),
        "MMM-yy"
      )}`,
    },
    series: [
      {
        name: "User Queries",
        data: [
          {
            name: "Physician",
            y: parseInt(data[data.length - 1]?.uuq_md.replace(/,/g, "")),
          },
          {
            name: "PA",
            y: parseInt(data[data.length - 1]?.uuq_pa.replace(/,/g, "")),
          },
          {
            name: "APRN",
            y: parseInt(data[data.length - 1]?.uuq_aprn.replace(/,/g, "")),
          },
          {
            name: "DDS",
            y: parseInt(data[data.length - 1]?.uuq_dds.replace(/,/g, "")),
          },
          {
            name: "Pharmacist (Dispensers)",
            y: parseInt(data[data.length - 1]?.uuq_rph.replace(/,/g, "")),
          },
          {
            name: "Delegate",
            y: parseInt(data[data.length - 1]?.uuq_delegate.replace(/,/g, "")),
          },
        ],
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}%",
          distance: -60,
          connectorShape: "straight",
        },
      },
    ],
  };
  const pieChartOptions3 = {
    chart: {
      type: "pie",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `Unique User Queries by Account Role ${format(
        data[data.length - 1]?.Date || Date.now(),
        "MMM-yy"
      )}`,
    },
    series: [
      {
        name: "User Queries",
        data: [
          {
            name: "Pharmacists",
            y: parseInt(data[data.length - 1]?.nepq_rph.replace(/,/g, "")),
          },
          {
            name: "Prescribers",
            y: parseInt(
              data[data.length - 1]?.nepq_prescribers.replace(/,/g, "")
            ),
          },
          {
            name: "Delegates",
            y: parseInt(data[data.length - 1]?.nepq_delegate.replace(/,/g, "")),
          },
        ],
        dataLabels: {
          enabled: true,
          format: "{point.name}: {point.percentage:.2f}%",
          distance: -60,
          connectorShape: "straight",
        },
      },
    ],
  };

  const chartOptions = {
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Unique PDMP Queries per Month",
    },
    xAxis: {
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true,
    },
    yAxis: {
      title: {
        text: "Count",
      },
    },
    series: [
      {
        name: "Number of Queries",
        data: filteredData.map((data) =>
          parseInt(data.number_estimated_patient_queries.replace(",", ""))
        ),
      },
    ],
  };
  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}></Grid>
        <Grid item lg={1}>
          {disclaimerComponent("userQueries")}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        marginTop={1}
      >
        <Grid item xs={12} md={4}>
          <Paper>
            <HighchartsReact
              highcharts={Highcharts}
              options={pieChartOptions}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <HighchartsReact
              highcharts={Highcharts}
              options={pieChartOptions2}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper>
            <HighchartsReact
              highcharts={Highcharts}
              options={pieChartOptions3}
            />
          </Paper>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "10px" }} />
      {datePickersComponent}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">
            Breakdown of Total Monthly Queries
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} style={{ marginBottom: "20px", border: ".5px solid lightgrey" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                      padding: 5,
                    }}
                  >
                    Month
                  </TableCell>
                  <TableCell> # Unique Patient Names Queried </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Estimated Patient Queries
                  </TableCell>

                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Prescribers{" "}
                  </TableCell>

                  <TableCell>Physicians </TableCell>
                  <TableCell>Advanced Practice RNs </TableCell>
                  <TableCell>Physician Assistants </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Dentists{" "}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Pharmacists
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Delegates
                  </TableCell>
                  <TableCell>% Change from Previous Month</TableCell>
                  <TableCell>Avergae Queries/Day</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {format(data.Date, "MMM-yy")}
                    </TableCell>
                    <TableCell>{data.unique_patient_names_queried}</TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.number_estimated_patient_queries}
                    </TableCell>

                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.nepq_prescribers}
                    </TableCell>
                    <TableCell>{data.nepq_md}</TableCell>
                    <TableCell>{data.nepq_aprn}</TableCell>
                    <TableCell>{data.nepq_pa}</TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.nepq_dds}
                    </TableCell>

                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.nepq_rph}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.nepq_delegate}
                    </TableCell>
                    <TableCell>{data.percent_change_previous_month}</TableCell>
                    <TableCell>{data.nepq_dailyaverage}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">
            Breakdown of Users (by role) Querying by Month
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} style={{ marginBottom: "20px", border: ".5px solid lightgrey" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Unique Users Performing Queries
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Prescribers
                  </TableCell>

                  <TableCell>Physicians</TableCell>
                  <TableCell>Advanced Practice RNs </TableCell>
                  <TableCell>Physician Assistants</TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Dentists
                  </TableCell>

                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Pharmacists
                  </TableCell>
                  <TableCell>Delegates</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{format(data.Date, "MMM-yy")}</TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.unique_user_queries}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.uuq_prescribers}
                    </TableCell>

                    <TableCell>{data.uuq_md}</TableCell>
                    <TableCell>{data.uuq_aprn}</TableCell>
                    <TableCell>{data.uuq_pa}</TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.uuq_dds}
                    </TableCell>

                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.uuq_rph}
                    </TableCell>
                    <TableCell>{data.uuq_delegate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">
            Breakdown of Unique Users Queried on a Daily Basis
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} style={{ marginBottom: "20px", border: ".5px solid lightgrey" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Patients Queried
                  </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    {" "}
                    Prescribers{" "}
                  </TableCell>

                  <TableCell>Physicians</TableCell>
                  <TableCell>Advanced Practice RNs</TableCell>
                  <TableCell> Physician Assistants </TableCell>
                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Dentists
                  </TableCell>

                  <TableCell
                    sx={{
                      borderRight: "1px solid lightgray", // Add a right border to the TableCell
                      "&:last-child": {
                        borderRight: "none", // Remove the border from the last TableCell
                      },
                    }}
                  >
                    Pharmacists
                  </TableCell>
                  <TableCell>Delegate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>{format(data.Date, "MMM-yy")}</TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.total_patients_queried}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.tpq_prescribers}
                    </TableCell>

                    <TableCell>{data.tpq_md}</TableCell>
                    <TableCell>{data.tpq_aprn}</TableCell>
                    <TableCell>{data.tpq_pa}</TableCell>

                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.tpq_dds}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: "1px solid lightgray", // Add a right border to the TableCell
                        "&:last-child": {
                          borderRight: "none", // Remove the border from the last TableCell
                        },
                      }}
                    >
                      {data.tpq_rph}
                    </TableCell>
                    <TableCell>{data.tpq_delegate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <Grid sx={{ mt: 4 }} component={Paper}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Grid>
    </>
  );
};

export default UserQueries;
