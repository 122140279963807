import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetSetData } from "../../services/useGetSetHook";

const DispenseBreakdownYearly = () => {
  const { filteredData, yearPickersComponent, disclaimerComponent } =
    useGetSetData(process.env.REACT_APP_BASE_API + "/dispense_breakdown", true);

  const transformData = (data) => {
    const transformedData = {};

    for (const item of data) {
      const date = new Date(parseInt(item.Date));
      const year = date.getFullYear().toString();

      if (!transformedData[year]) {
        transformedData[year] = {
          Year: year,
          rx_records: 0,
          cumulative_records: 0,
          rx_c2: 0,
          rx_c3: 0,
          rx_c4: 0,
          rx_c5: 0,
          rx_noncontrolled: 0,
          rx_unknown: 0,
          rx_controlled_substance: 0,
          rx_total_added: 0,
        };
      }

      transformedData[year].rx_records += parseInt(
        item.rx_records.replace(/,/g, "")
      );
      transformedData[year].cumulative_records += parseInt(
        item.cumulative_records.replace(/,/g, "")
      );
      transformedData[year].rx_c2 += parseInt(item.rx_c2.replace(/,/g, ""));
      transformedData[year].rx_c3 += parseInt(item.rx_c3.replace(/,/g, ""));
      transformedData[year].rx_c4 += parseInt(item.rx_c4.replace(/,/g, ""));
      transformedData[year].rx_c5 += parseInt(item.rx_c5.replace(/,/g, ""));
      transformedData[year].rx_noncontrolled += parseInt(
        item.rx_noncontrolled.replace(/,/g, "")
      );
      transformedData[year].rx_unknown += item.rx_unknown
        ? parseInt(item.rx_unknown.replace(/,/g, ""))
        : 0;
      transformedData[year].rx_controlled_substance += parseInt(
        item.rx_controlled_substance.replace(/,/g, "")
      );
      transformedData[year].rx_total_added += parseInt(
        item.rx_total_added.replace(/,/g, "")
      );
    }

    return Object.values(transformedData);
  };

  const transformedData = transformData(filteredData).sort((a, b) => {
    // Convert the date strings to actual Date objects for comparison
    const dateA = new Date(a.Year);
    const dateB = new Date(b.Year);

    // Compare the dates in descending order
    return dateB - dateA;
  });
  const chartOptions = {
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Annual Dispensed Prescription Records",
    },
    xAxis: {
      title: {
        text: "Dispenses",
      },
      categories: transformedData.map((data) => data.Year), // Exclude the first element
      reversed: true,
    },
    yAxis: {
      title: {
        text: "Number of Dispenses",
      },
    },
    plotOptions: {
      column: {
        colorByPoint: true, // Enable color per point
      },
    },
    series: [
      {
        name: "Records",
        data: transformedData.map((data) => parseInt(data.rx_records)), // Exclude the first element
      },
    ],
    tooltip: {
      formatter: function () {
        return `<span style="font-size: 10px">${this.x}</span><br/>
                <span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y}</b>`;
      },
    },
  };

  const chartOptions2 = {
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    title: {
      text: "Annual Dispenses by DEA Schedule",
    },
    xAxis: {
      categories: transformedData.map(function (obj) {
        return obj.Year;
      }),
      reversed: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Dispenses",
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      backgroundColor:
        (Highcharts.theme && Highcharts.theme.background2) || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          color:
            (Highcharts.theme && Highcharts.theme.dataLabelsColor) || "white",
        },
      },
    },
    series: [
      {
        name: "C2",
        data: transformedData.map(function (obj) {
          return obj.rx_c2;
        }),
      },
      {
        name: "C3",
        data: transformedData.map(function (obj) {
          return obj.rx_c3;
        }),
      },
      {
        name: "C4",
        data: transformedData.map(function (obj) {
          return obj.rx_c4;
        }),
      },
      {
        name: "C5",
        data: transformedData.map(function (obj) {
          return obj.rx_c5;
        }),
      },
    ],
  };

  const chartOptions3 = {
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    title: {
      text: "Annual Dispenses by Controlled and Non-controlled status",
    },
    xAxis: {
      categories: transformedData.map(function (obj) {
        return obj.Year;
      }),
      reversed: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of Dispenses",
      },
      stackLabels: {
        enabled: true,
        formatter: function () {
          return Highcharts.numberFormat(this.total / 1000000, 1) + "M";
        },
      },
    },
    legend: {
      backgroundColor:
        (Highcharts.theme && Highcharts.theme.background2) || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          color:
            (Highcharts.theme && Highcharts.theme.dataLabelsColor) || "white",
          formatter: function () {
            return Highcharts.numberFormat(this.y / 1000000, 1) + "M";
          },
        },
      },
    },
    series: [
      {
        name: "Non-Controlled",
        data: transformedData.map(function (obj) {
          return obj.rx_noncontrolled;
        }),
      },
      {
        name: "Controlled",
        data: transformedData.map(function (obj) {
          return obj.rx_c2 + obj.rx_c3 + obj.rx_c4 + obj.rx_c5;
        }),
      },
    ],
  };

  const tableConfigs2 = [
    {
      title: "Rx Data",
      columns: [
        "Year",
        "Yearly Reported Dispenses",
        "Controlled Substance",
        "C2",
        "C3",
        "C4",
        "C5",
        "Non-Controlled Substances",
        "Unknown",
      ],
      dataKeys: [
        "Year",
        "rx_records",
        "rx_controlled_substance",
        "rx_c2",
        "rx_c3",
        "rx_c4",
        "rx_c5",
        "rx_noncontrolled",
        "rx_unknown",
      ],
    },
  ];
  const renderTable2 = (config) => (
    <Grid item>
      <TableContainer component={Paper} style={{ width: "88vw", border: ".5px solid lightgrey"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell sx={{ textAlign: "center" }} key={index}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => (
                  <TableCell sx={{ textAlign: "center" }} key={index}>
                    {key !== "Year"
                      ? data[key].toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                        })
                      : data[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
          {yearPickersComponent}
        </Grid>
        <Grid item lg={1}>
          {disclaimerComponent("dispenseBreakdown")}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions3} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions2} />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {tableConfigs2.map((config, index) => (
          <React.Fragment key={index}>{renderTable2(config)}</React.Fragment>
        ))}
      </Grid>
    </>
  );
};

export default DispenseBreakdownYearly;
