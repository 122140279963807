import React from "react";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Divider,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetSetData } from "../../services/useGetSetHook";

const ProvisionedUsers = () => {
  const { filteredData, datePickersComponent, data, disclaimerComponent } =
    useGetSetData(process.env.REACT_APP_BASE_API + "/provisioned_users", false);

  const chartOptions = {
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Prescribers Provisioned in PDMP by Percent",
    },
    xAxis: {
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true,
    },
    yAxis: {
      max: 100,
      title: {
        text: "Percent Prescribers",
      },
      labels: {
        format: "{value}%",
      },
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}%",
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: "{y:.2f}%", // Display the value as a percentage
          inside: false, // Set to true if you want the labels inside the bars
          align: "center", // Set the alignment of the labels
          color: "inherit", // Set the color of the labels
          style: {
            textOutline: "none", // Remove the outline around the labels
            fontSize: "10px", // Adjust the font size of the labels
          },
        },

        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Prescribers Enrolled",
        data: filteredData.map((data) =>
          parseFloat(data.prescriber_percent_enrolled)
        ),
      },
      {
        name: "Prescribers Not Enrolled",
        data: filteredData.map(
          (data) => 100 - parseFloat(data.prescriber_percent_enrolled)
        ),
      },
    ],
    tooltip: {
      formatter: function () {
        const index = this.point.index; // Get the index of the current data point
        const presEnrolled = filteredData[index].prescribers_enrolled; // Get the PrescribersEnrolled value from filteredData
        const presNotEnrolled = filteredData[index].prescribers_not_enrolled;
        return `<span style="font-size: 10px">${this.x}</span><br/>
                  <span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${this.y.toFixed(1)}%</b> (${
          this.series.name === "Prescribers Enrolled"
            ? presEnrolled
            : presNotEnrolled
        })</b>
                  `;
      },
    },
  };
  const chartOptions2 = {
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Pharmacists (Dispensers) Provisioned in PDMP by Percent",
    },
    xAxis: {
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true,
    },
    yAxis: {
      title: {
        text: "Percent Pharmacists",
      },
      labels: {
        format: "{value}%",
      },
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}%",
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: "{y:.2f}%", // Display the value as a percentage
          inside: false, // Set to true if you want the labels inside the bars
          align: "center", // Set the alignment of the labels
          color: "#333", // Set the color of the labels
          style: {
            textOutline: "none", // Remove the outline around the labels
            fontSize: "10px", // Adjust the font size of the labels
          },
        },
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Pharmacists Enrolled",
        data: filteredData.map((data) =>
          parseFloat(data.pharmacist_percent_enrolled)
        ),
      },
      {
        name: "Pharmacists Not Enrolled",
        data: filteredData.map(
          (data) => 100 - parseFloat(data.pharmacist_percent_enrolled)
        ),
      },
    ],
    tooltip: {
      formatter: function () {
        const index = this.point.index; // Get the index of the current data point
        const pharEnrolled = filteredData[index].pharmacists_enrolled; // Get the PharEnrolled value from filteredData
        const pharNotEnrolled = filteredData[index].pharmacists_not_enrolled;
        return `<span style="font-size: 10px">${this.x}</span><br/>
                  <span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${this.y.toFixed(1)}%</b> (${
          this.series.name === "Pharmacists Enrolled"
            ? pharEnrolled
            : pharNotEnrolled
        })</b>
                  `;
      },
    },
  };

  const pieChartOption = {
    chart: {
      type: "pie",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    title: {
      text: `PDMP Provisioned Prescribers ${format(
        data[data.length - 1]?.Date || Date.now(),
        "MMM-yy"
      )}`,
    },
    tooltip: {
      pointFormat: "{point.percentage:.2f}%",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",

        showInLegend: true,
      },
    },
    series: [
      {
        name: "Enrollment Status",
        colorByPoint: true,
        data: [
          {
            name: "Prescribers Enrolled",
            y: parseFloat(
              data[data.length - 1]?.prescriber_percent_enrolled.replace(
                /,/g,
                ""
              )
            ),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.prescribers_enrolled
                })`;
              },
            },
          },

          {
            name: "Prescribers Not Enrolled",
            y:
              100 -
              parseFloat(
                data[data.length - 1]?.prescriber_percent_enrolled.replace(
                  /,/g,
                  ""
                )
              ),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.prescribers_not_enrolled
                })`;
              },
            },
          },
        ],
      },
    ],
  };
  const pieChartOption2 = {
    chart: {
      type: "pie",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `PDMP Provisioned Pharmacists (Dispensers) ${format(
        data[data.length - 1]?.Date || Date.now(),
        "MMM-yy"
      )}`,
    },
    tooltip: {
      pointFormat: "{point.percentage:.2f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Enrollment Status",
        colorByPoint: true,
        data: [
          {
            name: "Pharmacists Enrolled",
            y: parseFloat(
              data[data.length - 1]?.pharmacist_percent_enrolled.replace(
                /,/g,
                ""
              )
            ),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.pharmacists_enrolled
                })`;
              },
            },
          },
          {
            name: "Pharmacists Not Enrolled",
            y:
              100 -
              parseFloat(
                data[data.length - 1]?.pharmacist_percent_enrolled.replace(
                  /,/g,
                  ""
                )
              ),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.pharmacists_not_enrolled
                })`;
              },
            },
          },
        ],
      },
    ],
  };
  // Physicians, APRNs, Physician Assistants, and Dentists
  const pieChartOption3 = {
    chart: {
      type: "pie",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: `Provisioned Prescribers by RxCheck (PMIX) Role ${format(
        data[data.length - 1]?.Date || Date.now(),
        "MMM-yy"
      )}`,
    },
    tooltip: {
      pointFormat: "{point.percentage:.2f}%</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Enrollment Status",
        colorByPoint: true,
        data: [
          {
            name: "Physicians",
            y: parseInt(
              data[data.length - 1]?.rx_check_physicians.replace(/,/g, "")
            ),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.rx_check_physicians
                })`;
              },
            },
          },
          {
            name: "APRNs",
            y: parseInt(data[data.length - 1]?.rx_check_aprn.replace(/,/g, "")),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.rx_check_aprn
                })`;
              },
            },
          },
          {
            name: "Physicians Assistants",
            y: parseInt(data[data.length - 1]?.rx_check_pa.replace(/,/g, "")),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.rx_check_pa
                })`;
              },
            },
          },
          {
            name: "Dentists",
            y: parseInt(data[data.length - 1]?.rx_check_dds.replace(/,/g, "")),
            dataLabels: {
              enabled: true,
              formatter: function () {
                return `<b>${
                  this.point.name
                }</b>: ${this.point.percentage.toFixed(2)}% (${
                  data[data.length - 1]?.rx_check_dds
                })`;
              },
            },
          },
        ],
      },
    ],
  };
  const tableConfigs = [
    {
      title: "Enrollment Data",
      columns: [
        "Date",
        "Eligible Prescribers",
        "Enrolled Prescribers",
        "Physicians",
        "Advance Practice RNs (APRN)",
        "Physician Assistant",
        "Dentists",
        "Prescribers Not Enrolled",
        "Percent Enrolled",
        "Prescriber Delegate",
        "Eligible Pharmacists",
        "Enrolled Pharmacists",
        "Pharmacists Not Enrolled",
        "Percent Enrolled",
        "Pharmacist Delegate",
        "Total",
      ],
      dataKeys: [
        "Date",
        "eligible_prescribers",
        "prescribers_enrolled",
        "rx_check_physicians",
        "rx_check_aprn",
        "rx_check_pa",
        "rx_check_dds",
        "prescribers_not_enrolled",
        "prescriber_percent_enrolled",
        "prescriber_delegate",
        "eligible_pharmacists",
        "pharmacists_enrolled",
        "pharmacists_not_enrolled",
        "pharmacist_percent_enrolled",
        "dispenser_delegate",
        "total_user",
      ],
    },
  ];

  const renderTable = (config) => (
    <Grid>
      <TableContainer component={Paper} sx={{ width: "88vw", border: ".5px solid lightgrey"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => (
                  <TableCell
                    key={index}
                    style={{ padding: 5, textAlign: "center" }}
                  >
                    {" "}
                    {key === "Date" ? format(data[key], "MMM-yy") : data[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
      
        </Grid>
        <Grid item lg={1}>
          {" "}
          {disclaimerComponent("provisionedUsers")}
        </Grid>
      </Grid>
  
      <div style={{ marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Paper style={{ marginBottom: "15px", padding: "10px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={pieChartOption}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper style={{ marginBottom: "15px", padding: "10px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={pieChartOption2}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper style={{ marginBottom: "15px", padding: "10px" }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={pieChartOption3}
              />
            </Paper>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: "10px" }} />
        {datePickersComponent}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions2} />
          </Paper>
        </Grid>
      </Grid>

      <Grid>{tableConfigs.map((config, index) => renderTable(config))}</Grid>
    </>
  );
};

export default ProvisionedUsers;
