import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetSetData } from "../../services/useGetSetHook";

const NaloxoneDispenses = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } = useGetSetData(
    process.env.REACT_APP_BASE_API + "/naloxone",
    true
  );
  const transformData = (data) => {
    const transformedData = {};

    for (const item of data) {
      const date = new Date(parseInt(item.Date));
      const yearKey = date.getFullYear().toString();

      if (transformedData[yearKey]) {
        transformedData[yearKey].number_naloxone_rx += parseInt(
          item.number_naloxone_rx.replace(/,/g, "")
        );
        transformedData[yearKey].number_unique_naloxone_pt += parseInt(
          item.number_unique_naloxone_pt.replace(/,/g, "")
        );
      } else {
        transformedData[yearKey] = {
          Year: yearKey,
          number_naloxone_rx: parseInt(
            item.number_naloxone_rx.replace(/,/g, "")
          ),
          number_unique_naloxone_pt: parseInt(
            item.number_unique_naloxone_pt.replace(/,/g, "")
          ),
        };
      }
    }

    return Object.values(transformedData);
  };

  const transformedData = transformData(filteredData).sort((a, b) => {
    // Convert the date strings to actual Date objects for comparison
    const dateA = new Date(a.Year);
    const dateB = new Date(b.Year);

    // Compare the dates in descending order
    return dateB - dateA;
  });

  const chartOptions = {
    chart: {
      height: 355,
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Naloxone Dispenses per Year - by Dispenses vs by Patient",
    },
    xAxis: {
      categories: transformedData.map((data) => data.Year), // Exclude the first element
      reversed: true,
     
    },
    yAxis: {
      min: 0,
      title: {
        text: " Naloxone Dispenses",
      },
    },
    series: [
      {
        name: "By Dispenses",
        data: transformedData.map((data) => parseInt(data.number_naloxone_rx)), // Exclude the first element
      },
      {
        name: "By Patients",
        data: transformedData.map((data) =>
          parseInt(data.number_unique_naloxone_pt)
        ), // Exclude the first element
      },
    ],
    tooltip: {
      formatter: function () {
        return `<span style="font-size: 10px">${this.x}</span><br/>
                <span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${this.y}</b>`;
      },
    },
  };

  const generateTableData = (data) => {
    const tableData = {};

    // Group data by year and calculate sum for each month
    data.forEach((item) => {
      const date = new Date(parseInt(item.Date));
      const year = date.getFullYear().toString();
      const month = date.toLocaleString("en-US", { month: "long" });

      if (!tableData[year]) {
        tableData[year] = { Year: year };
      }

      tableData[year][month] = parseInt(
        item.number_naloxone_rx.replace(/,/g, "")
      );
    });

    return Object.values(tableData);
  };
  const generateTableData2 = (data) => {
    const tableData = {};

    // Group data by year and calculate sum for each month
    data.forEach((item) => {
      const date = new Date(parseInt(item.Date));
      const year = date.getFullYear().toString();
      const month = date.toLocaleString("en-US", { month: "long" });

      if (!tableData[year]) {
        tableData[year] = { Year: year };
      }

      tableData[year][month] = parseInt(
        item.number_unique_naloxone_pt.replace(/,/g, "")
      );
    });

    return Object.values(tableData);
  };

  let tableData = generateTableData(filteredData).sort((a, b) => {
    // Convert the date strings to actual Date objects for comparison
    const dateA = new Date(a.Year);
    const dateB = new Date(b.Year);

    // Compare the dates in descending order
    return dateB - dateA;
  });
  const tableData2 = generateTableData2(filteredData).sort((a, b) => {
    // Convert the date strings to actual Date objects for comparison
    const dateA = new Date(a.Year);
    const dateB = new Date(b.Year);

    // Compare the dates in descending order
    return dateB - dateA;
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const chartOptions2 = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    title: {
      text: "Yearly Data",
    },
    xAxis: {
      categories: months,
      crosshair: true,
   
    },
    yAxis: {
      title: {
        text: "Naloxone Dispenses",
      },
    },
    series: tableData.map((obj) => ({
      name: obj.Year,
      data: months.map((month) => obj[month] || null),
    })),
  };
  const columns = [
    "Year",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const tableConfigs2 = [
    {
      title: "Rx Data",
      columns: ["Year", "Dispenses", "Individual Patients"],
      dataKeys: ["Year", "number_naloxone_rx", "number_unique_naloxone_pt"],
    },
  ];
  const renderTable2 = (config) => (
    <Grid item>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => (
                  <TableCell key={index}> {data[key]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
  return (
    <>
    
      <Grid container spacing={10}>
        <Grid item lg={9}>
        {datePickersComponent}
        </Grid>
        <Grid item lg={1}>
          {" "}
          {disclaimerComponent("naloxoneDispenses")}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          {tableConfigs2.map((config, index) => (
            <React.Fragment key={index}>{renderTable2(config)}</React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Paper style={{ marginBottom: "15px", padding: "10px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions2} />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <h2> Number of Naloxone Dispenses</h2>
          <TableContainer component={Paper} sx={{border: ".5px solid lightgrey"}}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {row[column] ? row[column] : ""}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={12}>
          <h2>Individual Patients with Naloxone Dispenses – By Month</h2>
          <TableContainer component={Paper} sx={{border: ".5px solid lightgrey"}}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell key={index}>{column}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData2.map((row, index) => (
                  <TableRow key={index}>
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {row[column] ? row[column] : ""}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default NaloxoneDispenses;
