import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import config from "./oktaConfig";
import AppRoutes from "./routes/AppRoutes";

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const navigate = useNavigate();
 
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };


  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <AppRoutes/>
      </div>
    </Security>
  );
};

export default App;
