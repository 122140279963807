import React, { useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  TableContainer,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetSetData } from "../../services/useGetSetHook";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DataGrid } from "@mui/x-data-grid";

const RegisteredDispensers = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } =
    useGetSetData(
      process.env.REACT_APP_BASE_API + "/registered_dispensers",
      false
    );

  // const chartRef = useRef(null);

  // const handleExportPDF = () => {
  //   // Get the chart container element
  //   const chartContainer = chartRef.current;

  //   // Get the scrollable parent element
  //   const scrollableParent = chartContainer.closest(".highcharts-scrolling");

  //   // Create a canvas from the scrollable parent element
  //   html2canvas(chartContainer).then((canvas) => {
  //     // Get the chart image data as a PNG
  //     const chartImage = canvas.toDataURL("image/png");

  //     // Create a new PDF documents
  //     const pdf = new jsPDF();
  //     const logoImage = new Image();
  //     logoImage.src = `${process.env.PUBLIC_URL + "/CHLogo.png"}`;
  //     // pdf.addImage(imageData, imageType, x, y, width, height);

  //     pdf.addImage(logoImage, "PNG", 10, 3, 25, 9);
  //     // Add the chart image to the PDF
  //     pdf.addImage(chartImage, "PNG", 10, 30, 200, 80);

  //     // Download the PDF file
  //     pdf.save("chart.pdf");
  //   });
  // };
  const chartOptions = {
    chart: {
      type: "line",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // {
            //   text: "Download as PDF",
            //   onclick: () => handleExportPDF("downloadPDF"),
            // },
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Percent Total Dispensers Remaining to Register to Submit Data to PDMP",
    },
    xAxis: {
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true,
    },
    yAxis: {
      title: {
        text: "Total Percent Unregistered",
      },
      labels: {
        format: "{value}%",
      },
    },
    series: [
      {
        name: "Total Dispensers Remaining",
        data: filteredData.map((data) =>
          parseFloat(data.percent_total_remaining)
        ),
        dataLabels: {
          enabled: true,

          formatter: function () {
            const percentTotalRemaining =
              this.series.chart.series[1].yData[this.point.index];
            return `${this.y.toFixed(2)}% (${percentTotalRemaining})`;
          },
        },
      },
      {
        name: "Total Remaining",

        data: filteredData.map((data) => parseInt(data.total_remaining)),
        showInLegend: false, // Exclude from the legend
        visible: false,
      },
    ],
    tooltip: {
      formatter: function () {
        const percentTotalRemaining =
          this.series.chart.series[1].yData[this.point.index];
        return `<span style="font-size: 10px">${this.x}</span><br/>
                  <span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${this.y.toFixed(1)}%</b> (${percentTotalRemaining})</b>
                  `;
      },
    },
  };

  const tableConfigs = [
    {
      title: "Total",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Percent Registered and Exempt",
        "Total Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "total_pharmacy",
        "total_registered_pharmacy",
        "total_exempt_pharmacy",
        "pecent_total_registered_and_exempt",
        "total_remaining",
        "percent_total_remaining",
      ],
    },
    {
      title: "Community Pharmacy Licenses",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "community_pharmacy_licenses",
        "cpl_registered",
        "cpl_exempt",
        "cpl_remaining",
        "cpl_percentremaining",
      ],
    },
    {
      title: "Mail Service Pharmacy",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "mail_service_pharmacy",
        "msp_registered",
        "msp_exempt",
        "msp_remaining",
        "msp_percentremaining",
      ],
    },
    {
      title: "Remote Dispensing",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "remote_dispensing",
        "rd_registered",
        "rd_exempt",
        "rd_remaining",
        "rd_percentremaining",
      ],
    },
    {
      title: "Delegated Dispensing Permit",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "delegated_dispensing_permit",
        "ddp_registered",
        "ddp_exempt",
        "ddp_remaining",
        "ddp_percentremaining",
      ],
    },
    {
      title: "Dispensing Practitioner Pharmacy Permit",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "dispensing_practitioner_pharmacy_permit",
        "dppp_registered",
        "dppp_exempt",
        "dppp_remaining",
        "dppp_percentremaining",
      ],
    },
    {
      title: "Long-Term Care Automated Pharmacy",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "long_term_care_automated_pharmacy",
        "ltcap_registered",
        "ltcap_exempt",
        "ltcap_remaining",
        "ltcap_percentremaining",
      ],
    },
    {
      title: "Mail-Order Ophthalmic Provider",
      columns: [
        "Month",
        "Total",
        "Registered",
        "Exempt",
        "Remaining",
        "% Remaining",
      ],
      dataKeys: [
        "Date",
        "mail_order_ophthalmic_provider",
        "moop_registered",
        "moop_exempt",
        "moop_remaining",
        "moop_percentremaining",
      ],
    },
  ];

  const renderTable = (config) => (
    <Grid item xs={12} md={6} xl={6}>
      <Paper sx={{ padding: "5px", backgroundColor: "#9ac6eb" }}>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          {config.title}
        </Typography>
      </Paper>
      <TableContainer
        style={{ border: ".5px solid gray", maxHeight: "600px" }}
        component={Paper}
      >
        <DataGrid
          rows={filteredData}
          columns={config.columns
            .filter((column) => column !== "% Remaining")
            .map((column, index) => {
              if (column === "Remaining") {
                return {
                  field: config.dataKeys[4],
                  headerName: column,
                  flex: 1,

                  renderCell: (params) => (
                    <TableCell
                      style={{
                        whiteSpace: "nowrap",
                     
                      }}
                    >
                      {`${params.row[config.dataKeys[4]]} (${
                        params.row[config.dataKeys[5]]
                      })`}
                    </TableCell>
                  ),
                };
              }
              if (column === "Total Remaining") {
                return {
                  field: config.dataKeys[5],
                  headerName: column,

                  flex: 1,
                  renderCell: (params) => (
                    <TableCell
                      style={{
                        whiteSpace: "nowrap",
                     
                      }}
                    >
                      {`${params.row[config.dataKeys[5]]} (${
                        params.row[config.dataKeys[6]]
                      })`}
                    </TableCell>
                  ),
                };
              }
              return {
                field:
                  column === "Month"
                    ? config.dataKeys[0]
                    : config.dataKeys[index],
                headerName: column,
                
                flex: 1,

                renderCell: (params) => (
                  <TableCell
                    style={{
                      whiteSpace: "nowrap",
                 
                    }}
                  >
                    {column === "Month"
                      ? format(params.row[config.dataKeys[0]], "MMM-yy")
                      : params.row[config.dataKeys[index]]}
                  </TableCell>
                ),
              };
            })}
          size="small" // Set the size to small
          density="compact"
          hideFooter
        />
      </TableContainer>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={9}>
          {datePickersComponent}
        </Grid>
        <Grid item lg={1}>
          {disclaimerComponent("registeredDispensers")}
        </Grid>
      </Grid>

      <Paper style={{ marginBottom: "15px", padding: "10px" }}>
        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      </Paper>

      <Grid container spacing={2}>
        {tableConfigs.map((config, index) => (
          <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
        ))}
      </Grid>
    </>
  );
};

export default RegisteredDispensers;
