import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { format } from "date-fns";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useGetSetData } from "../../services/useGetSetHook";

const StateQueries = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } =
    useGetSetData(
      process.env.REACT_APP_BASE_API + "/intra_state_queries",
      false
    );

  const tableConfigs = [
    {
      title: "Rx Data",
      columns: [
        "Month",
        "Provided",
        "% Provided",
        "Not Found",
        "% Not Found",
        "No Access",
        "% No Access",
        "States",
        "From Colorado",
        "From South Dakota",
        "From Illinois",
        "From Maryland",
        "From Wisconsin",
      ],
      dataKeys: [
        "Date",
        "incoming_provided",
        "incoming_percent_provided",
        "incoming_not_found",
        "incoming_percent_not_found",
        "incoming_no_access",
        "incoming_percent_no_access",
        "incoming_states",
        "incoming_from_co",
        "incoming_from_sd",
        "incoming_from_il",
        "incoming_from_md",

        "incoming_from_wi",
      ],
    },
  ];

  const tableConfigs2 = [
    {
      title: "Rx Data",
      columns: [
        "Month",
        "Provided",
        "% Provided",
        "Not Found",
        "% Not Found",
        "No Access",
        "% No Access",
        "States",
        "To Colorado",
        "To Kansas",
        "To South Dakota",
        "To Illinois",
      ],
      dataKeys: [
        "Date",
        "outgoing_provided",
        "outgoing_percent_provided",
        "outgoing_not_found",
        "outgoing_percent_not_found",
        "outgoing_no_access",
        "outgoing_percent_no_access",
        "outgoing_states",
        "outgoing_to_co",
        "outgoing_to_ks",
        "outgoing_to_sd",
        "outgoing_to_il",
      ],
    },
  ];

  const tableConfigs3 = [
    {
      title: "Rx Data",
      columns: [
        "Month",
        "Provided",
        "% Provided",
        "Not Found",
        "% Not Found",
        "No Access",
        "% No Access",
        "CHI Health",
        "Nebraska Medicine",
        "Nebraska Methodist",
        "Children’s Hospital and Clinics",
        "Franklin County Memorial Hospital",
        "Perkins County Hospital District",
        "Nemaha County Hospital",
        "Chase County Hospital",
        "Pender Community Hospital",
        "Abacus Pharmacy Software",
        "PDX Pharmacies",
      ],
      dataKeys: [
        "Date",
        "wfi_provided",
        "wfi_percent_provided",
        "wfi_not_found",
        "wfi_percent_not_found",
        "wfi_no_access",
        "wfi_percent_no_access",
        "wfi_ne_chi",
        "wfi_ne_neb",
        "wfi_ne_nmh",
        "wfi_ne_chs",
        "wfi_ne_fmh",
        "wfi_ne_prk",
        "wfi_ne_nem",
        "wfi_ne_cch",
        "wfi_ne_pen",
        "wfi_ne_aba",
        "wfi_ne_pdx",
      ],
    },
  ];
  const renderTable = (config) => (
    <Grid item>
      <TableContainer component={Paper} style={{ width: "100%", border: ".5px solid lightgrey" }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => (
                  <TableCell key={index}>
                    {" "}
                    {key === "Date" ? format(data[key], "MMM-yy") : data[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
          {datePickersComponent}
        </Grid>
        <Grid item lg={1}>
          {" "}
          {disclaimerComponent("interIntra")}
        </Grid>
      </Grid>
      <Accordion sx={{ width: "88vw" }}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">Incoming Queries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid spacing={2}>
            {tableConfigs.map((config, index) => (
              <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "88vw" }}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">Outgoing Queries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {tableConfigs2.map((config, index) => (
            <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ width: "88vw" }}>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">
            Workflow Integration (WFI) Queries
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {tableConfigs3.map((config, index) => (
            <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default StateQueries;
