import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useGetSetData } from "../../services/useGetSetHook";

const MmeAlerts = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } =
    useGetSetData(process.env.REACT_APP_BASE_API + "/mme_alerts", false);

  const transformDataToYearly = (data) => {
    const yearlyData = [];

    // Helper function to extract the year from a timestamp
    const getYear = (timestamp) => new Date(timestamp).getFullYear();

    // Iterate over the array of objects
    data.forEach((obj) => {
      const year = getYear(obj.Date);

      // Find the yearly object in 'yearlyData' or create a new one if it doesn't exist
      let yearObj = yearlyData.find((item) => item.Date === year);
      if (!yearObj) {
        yearObj = {
          Date: year,
          mme_total_patients_queried: 0,
          patients_with_mme: 0,
          dayave_7_050: 0,
          dayave_7_5090: 0,
          dayave_7_90p: 0,
          dayave_7_ta: 0,
          dayave_30_050: 0,
          dayave_30_5090: 0,
          dayave_30_90p: 0,
          dayave_30_ta: 0,
          mme_total_unique_patient_queried: 0,
          unique_patients_with_mme: 0,
          unique_7_dayave_050: 0,
          unique_7_dayave_5090: 0,
          unique_7_dayave_90p: 0,
          unique_7_dayave_ta: 0,
          unique_30_dayave_050: 0,
          unique_30_dayave_5090: 0,
          unique_30_dayave_90p: 0,
          unique_30_dayave_ta: 0,
        };
        yearlyData.push(yearObj);
      }

      yearObj.mme_total_patients_queried += Number(
        obj.mme_total_patients_queried
      );
      yearObj.patients_with_mme += Number(obj.patients_with_mme);
      yearObj.dayave_7_050 += Number(obj.dayave_7_050);
      yearObj.dayave_7_5090 += Number(obj.dayave_7_5090);
      yearObj.dayave_7_90p += Number(obj.dayave_7_90p);
      yearObj.dayave_7_ta += Number(obj.dayave_7_ta);
      yearObj.dayave_30_050 += Number(obj.dayave_30_050);
      yearObj.dayave_30_5090 += Number(obj.dayave_30_5090);
      yearObj.dayave_30_90p += Number(obj.dayave_30_90p);
      yearObj.dayave_30_ta += Number(obj.dayave_30_ta);
      yearObj.mme_total_unique_patient_queried += Number(
        obj.mme_total_unique_patient_queried
      );
      yearObj.unique_patients_with_mme += Number(obj.unique_patients_with_mme);
      yearObj.unique_7_dayave_050 += Number(obj.unique_7_dayave_050);
      yearObj.unique_7_dayave_5090 += Number(obj.unique_7_dayave_5090);
      yearObj.unique_7_dayave_90p += Number(obj.unique_7_dayave_90p);
      yearObj.unique_7_dayave_ta += Number(obj.unique_7_dayave_ta);
      yearObj.unique_30_dayave_050 += Number(obj.unique_30_dayave_050);
      yearObj.unique_30_dayave_5090 += Number(obj.unique_30_dayave_5090);
      yearObj.unique_30_dayave_90p += Number(obj.unique_30_dayave_90p);
      yearObj.unique_30_dayave_ta += Number(obj.unique_30_dayave_ta);
    });

    // Calculate the average for each field in each yearly object
    yearlyData.forEach((yearObj) => {
      const count = data.filter(
        (obj) => getYear(obj.Date) === yearObj.Date
      ).length;
      for (const field in yearObj) {
        if (field !== "Date") {
          yearObj[field] /= count;
        }
      }
    });

    return yearlyData;
  };

  const transformedData = transformDataToYearly(filteredData);

  const tableConfigs = [
    {
      title: "Rx Data",
      columns: ["Year", "7-Day Average MME", "30-Day Average MME"],
      dataKeys: ["Date", "dayave_7_ta", "dayave_30_ta"],
    },
  ];

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "line",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    title: {
      text: "Queried MME Monthly Average",
    },
    xAxis: {
      categories: filteredData.map((obj) => format(obj.Date, "MMM-yy")),
      title: {
        text: "Months",
      },
      reversed: true,
    },
    yAxis: {
      title: {
        text: "Value",
      },
    },
    series: [
      {
        name: "7-Day Average",
        data: filteredData?.map((obj) => parseFloat(obj["dayave_7_ta"])),
      },
      {
        name: "30-Day Average",
        data: filteredData?.map((obj) => parseFloat(obj["dayave_30_ta"])),
      },
    ],
  };

  const generateTables = (data) => {
    return (
      <Grid container spacing={2}>
        {data.map((record) => (
          <Grid item xs={6} key={record.Date}>
            <Typography variant="h6" gutterBottom>
              {format(record.Date, "MMM-yy")}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Total Patients Queried: {record.mme_total_patients_queried}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Patients with MME: {record.patients_with_mme}
            </Typography>
            <TableContainer sx={{ border: ".5px solid lightgrey"}}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Range</TableCell>
                    <TableCell>7-Day Ave</TableCell>
                    <TableCell>30-Day Ave</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>0-50.0</TableCell>
                    <TableCell>{record["dayave_7_050"]}</TableCell>
                    <TableCell>{record["dayave_30_050"]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>50.001-90</TableCell>
                    <TableCell>{record["dayave_7_5090"]}</TableCell>
                    <TableCell>{record["dayave_30_5090"]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>&gt;90.001</TableCell>
                    <TableCell>{record["dayave_7_90p"]}</TableCell>
                    <TableCell>{record["dayave_30_90p"]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Average</TableCell>
                    <TableCell>{record["dayave_7_ta"]}</TableCell>
                    <TableCell>{record["dayave_30_ta"]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      </Grid>
    );
  };
  const generateTables2 = (data) => {
    return (
      <Grid container spacing={2}>
        {data.map((record) => (
          <Grid item xs={6} key={record.Date}>
            <Typography variant="h6" gutterBottom>
              {format(record.Date, "MMM-yy")}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Total Unique Patients Queried:{" "}
              {record.mme_total_unique_patient_queried}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Unique Patients with MME: {record.unique_patients_with_mme}
            </Typography>
            <TableContainer sx={{border: ".5px solid lightgrey"}}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Range</TableCell>
                    <TableCell>7-Day Ave</TableCell>
                    <TableCell>30-Day Ave</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>0-50.0</TableCell>
                    <TableCell>{record["unique_7_dayave_050"]}</TableCell>
                    <TableCell>{record["unique_30_dayave_050"]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>50.001-90</TableCell>
                    <TableCell>{record["unique_7_dayave_5090"]}</TableCell>
                    <TableCell>{record["unique_30_dayave_5090"]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>&gt;90.001</TableCell>
                    <TableCell>{record["unique_7_dayave_90p"]}</TableCell>
                    <TableCell>{record["unique_30_dayave_90p"]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Average</TableCell>
                    <TableCell>{record["unique_7_dayave_ta"]}</TableCell>
                    <TableCell>{record["unique_30_dayave_ta"]}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))}
      </Grid>
    );
  };
  const tables = generateTables(filteredData);
  const tables2 = generateTables2(filteredData);
  const renderTable = (config) => (
    <Grid container>
      <TableContainer
        component={Paper}
        style={{ marginBottom: "10px", }}
      >
        <Typography
          sx={{ float: "right", color: "gray", fontSize: 13, padding: 1 }}
        >
          {" "}
          * Disclaimer: Table is getting calculated from filtered data.
        </Typography>

        <Table size="small">
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => {
                  const value =
                    key === "Date" ? data[key] : parseFloat(data[key]).toFixed(2);
                  return <TableCell key={index}>{value}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
          {datePickersComponent}
        </Grid>
        <Grid item> {disclaimerComponent("mmeAlerts")}</Grid>
      </Grid>
      {tableConfigs.map((config, index) => (
        <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
      ))}
      <Paper style={{ marginBottom: "15px", padding: "10px" }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Paper>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">All Patient Alerts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Your table code for All Patient Alerts */}
          {tables}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
          <Typography variant="h6">All Unique Patient Alerts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Your table code for All Patient Alerts */}
          {tables2}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MmeAlerts;
