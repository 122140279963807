import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Box,
  Paper,
  CircularProgress,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";

const AccessDenied = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={4}>
          <Paper sx={{ p: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Access Denied
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={4}
            >
              <LockOutlined fontSize="large" />
            </Box>
            <Typography variant="body1" align="center">
              Sorry, you do not have access to view this page. Please reach out
              to your administrator!
            </Typography>
          </Paper>
        </Box>
      )}
    </Container>
  );
};

export default AccessDenied;
