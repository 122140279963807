import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Welcome from "../pages/Welcome";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Dashboard,
  PowerOff,
  AssessmentRounded,
  DonutLargeRounded,
} from "@mui/icons-material";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar({ setCorsErrorModalOpen }) {
  const theme = useTheme();
  const { authState, oktaAuth } = useOktaAuth();
  const [open, setOpen] = React.useState(false);
  const [userInfo, setUserInfo] = useState("");
  const navigate = useNavigate();
  const [value1, setValue] = React.useState(0);

  const statsOptions = [
    { label: "Registered Dispensers", anchor: "registered-dispensers" },
    { label: "Provisioned Users", anchor: "provisioned-users" },
    { label: "MME Alerts", anchor: "mme-alerts" },
    { label: "User Queries", anchor: "user-queries" },
    { label: "MPE Alerts", anchor: "mpe-alerts" },
    { label: "Opioid - Benzo Overlap", anchor: "opioid-benzo-overlap" },
    { label: "Inter/Intra State Queries", anchor: "inter-intra-state-queries" },
    {
      label: "Dispense Breakdown Monthly",
      anchor: "dispense-breakdown-monthly",
    },
    { label: "Dispense Breakdown Yearly", anchor: "dispense-breakdown-yearly" },
    { label: "Data Metrics", anchor: "data-metrics" },
    { label: "Naloxone Dispenses", anchor: "naloxone-dispenses" },
    { label: "Top Drug class breakdown", anchor: "top-drug-class-breakdown" },
  ];

  const mesOptions = [
    { label: "Scorecard", anchor: "scorecard" },
    { label: "Messaging and Queries", anchor: "messaging-queries" },
    { label: "Usablity and Integrations", anchor: "usablity-integrations" },
    { label: "Controlled Substances", anchor: "controlled-substances" },

    { label: "Drug Class Breakdown - Opioid", anchor: "dcb-opioid" },
    {
      label: "Drug Class Breakdown - Sedative & BZD",
      anchor: "dcb-sedative-bzd",
    },
    { label: "Drug Class Breakdown - Stimulants", anchor: "dcb-stimulants" },
    {
      label: "Multiple Prescription Breakdown",
      anchor: "mpb",
    },
    {
      label: "Overlap and High Opioid Usage Indicators",
      anchor: "overlap-high-opiod-usage-indicators",
    },
  ];
  const handleLinkClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";
  const logout = async () => {
    try {
      await oktaAuth.signOut();
      // const accessToken = oktaAuth.getAccessToken();
      // const idToken = oktaAuth.getIdToken();
      // if (accessToken) {
      //   await fetch('https://cynchealth.oktapreview.com/oauth2/v1/revoke', {
      //     method: 'POST',
      //     headers: {
      //       Authorization: `Bearer ${accessToken}`,
      //     },
      //   });
      // }
  
      // if (idToken) {
      //   await fetch('https://cynchealth.oktapreview.com/oauth2/v1/revoke', {
      //     method: 'POST',
      //     headers: {
      //       Authorization: `Bearer ${idToken}`,
      //     },
      //   });
      // }
      // window.location.href = 'https://cynchealth.oktapreview.com/oauth2/v1/logout';
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  useEffect(() => {
    const anchor = window.location.hash;
    if (anchor) {
      const selectedTab = anchor.substring(1); // Remove the '#' symbol
      const tabIndex = currentOptions.findIndex(
        (tab) => tab.anchor === selectedTab
      );
      if (tabIndex !== -1) {
        setValue(tabIndex);
      }
    }
  }, [window.location.hash]);
  const handleAutoFIlterClick = (options, option) => {
    setValue(options.indexOf(option));
    window.location.hash = option.anchor;
  };

  const currentPath = window.location.pathname;

  const currentOptions = currentPath === "/stats" ? statsOptions : mesOptions;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {authState && authState.isAuthenticated && (
        <>
          <AppBar
            position="fixed"
            open={open}
            sx={{
              backdropFilter: "blur(10px)",
              backgroundColor: "transparent",
              color: "black",
            }}
          >
            <Toolbar sx={{ display: "flex" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={process.env.PUBLIC_URL + "/CHicon.png"}
                width="35"
                height="35"
              ></img>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1, margin: 1 }}
              >
                CyncHealth
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 1 }}
              >
                PDMP Dashboard
              </Typography>
              {currentPath === "/stats" || currentPath === "/mes" ? (
                <FormControl
                  sx={{ flexGrow: 1, margin: 1 }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel>Select tab</InputLabel>
                  <Select
                    label="Select tab"
                    value={currentOptions[value1] || null}
                    onChange={(event) =>
                      handleAutoFIlterClick(currentOptions, event.target.value)
                    }
                  >
                    {currentOptions.map((option) => (
                      <MenuItem key={option.value} value={option}>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`${currentPath}#${option.anchor}`}
                        >
                          {option.label}
                        </Link>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              <Button
                color="primary"
                variant="contained"
                id="logout-button"
                onClick={logout}
                endIcon={<PowerOff />}
              >
                {" "}
                Logout
              </Button>
            </Toolbar>
          </AppBar>
        </>
      )}

      {authState && authState.isAuthenticated && (
        <>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleLinkClick("/menu")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary="Menu" sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleLinkClick("/stats")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AssessmentRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary="PDMP Usage Metrics"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              {/* <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => handleLinkClick("/mes")}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <DonutLargeRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary="CMS Scorecard"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem> */}

              {/* <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText
                    primary="PDMP Historical Lookups"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText
                    primary="PDMP Compliance"
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem> */}
            </List>
          </Drawer>
        </>
      )}
      <Box sx={{ p: 1 }}>
        <div>{authState && !authState.isAuthenticated && <Welcome />}</div>
      </Box>
    </Box>
  );
}
