 const config =  {
  oidc: {
    clientId: process.env.REACT_APP_CLIENT_ID || "0oa13mxua13AlAQv80h8",
    issuer: process.env.REACT_APP_ISSUER_URL || "https://cynchealth.oktapreview.com/oauth2/default",
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ["openid", "profile", "email", "groups"],
    pkce: true,
    disableHttpsCheck: false,
  },
  resourceServer: {
    messagesUrl: "http://localhost:8000/api/messages",
  },
};
export default config;
