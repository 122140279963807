import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./SecureRoute";
import Menu from "../pages/Menu";
import Pages from "../layout/Pages";
import PdmpStats from "../pages/PdmpStats";
import AccessDenied from "../pages/AccessDenied";

const AppRoutes = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info.groups);
      });
    }
  }, [authState, oktaAuth]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Pages>
            <LoginCallback />
          </Pages>
        }
      />
      <Route path="login/callback" element={<LoginCallback />} />
      <Route path="/menu" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              <Menu />
            </Pages>
          }
        />
      </Route>
      <Route path="/stats" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              { userInfo && userInfo.includes("PDMP Usage Metrics") ? (
                <PdmpStats />
              ) : (
                <AccessDenied />
              )}
            </Pages>
          }
        />
      </Route>
      {/* <Route path="/mes" element={<RequiredAuth />}>
        <Route
          path=""
          element={
            <Pages>
              {userInfo &&  userInfo.includes("PDMP CMS Scorecard") ? (
                <Mes />
              ) : (
                <AccessDenied />
              )}
            </Pages>
          }
        />
      </Route> */}
    </Routes>
  )
};

export default AppRoutes;
