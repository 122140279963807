import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useOktaAuth } from "@okta/okta-react";
import { Divider } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://cynchealth.org/">
        CyncHealth
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide({ setCorsErrorModalOpen }) {
  const { authState, oktaAuth } = useOktaAuth();
  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";

  const login = async () => {
    try {
      await oktaAuth.signInWithRedirect({ originalUri: "/menu" });
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
    <Grid container component="main" sx={{ height: "96vh", width: "96vw" }}>
      <Grid
        item
        xs={false}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          backgroundImage: "url(/CHicon.png)",
      
          backgroundColor: (t) =>
            t.palette.mode === "light" ? t.palette.grey[200] : t.palette.grey[900],
          // backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent:"center"
        }}
      >
        <Grid
          item         
          xs={12}
          sm={8}
          md={4}
          lg={4}
          
          component={Paper}
          elevation={3}
        >
          <Box
            sx={{
              my: 2,
              mx: 5,
              py: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
         
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 6 }}
            >
              <Typography variant="h3" align="center">
                Welcome to PDMP Dashboard
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 1 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Typography variant="body2">
                    This will redirect you to Cynchealth's Okta Login Page.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  </ThemeProvider>
  );
}
