import React, { useEffect } from "react";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import RegisteredDispensers from "../components/usageMetrics/RegisteredDispensers";
import UserQueries from "../components/usageMetrics/UserQueries";
import MpeAlerts from "../components/usageMetrics/MpeAlerts";
import Opioid from "../components/usageMetrics/Opioid";
import ProvisionedUsers from "../components/usageMetrics/ProvisionedUsers";
import DispenseBreakdown from "../components/usageMetrics/DispenseBreakdown";
import DispenseBreakdownYearly from "../components/usageMetrics/DispenseBreakdownYearly";
import MmeAlerts from "../components/usageMetrics/MmeAlerts";
import StateQueries from "../components/usageMetrics/StateQueries";
import DataMetric from "../components/usageMetrics/DataMetric";
import NaloxoneDispenses from "../components/usageMetrics/NaloxoneDispenses";
import TopDrugClass from "../components/usageMetrics/TopDrugClass";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const PdmpStats = () => {
  const [value, setValue] = React.useState(0);
  const tabs = [
    { label: "Registered Dispensers", anchor: "registered-dispensers" },
    { label: "Provisioned Users", anchor: "provisioned-users" },
    { label: "User Queries", anchor: "user-queries" },
    { label: "MME Alerts", anchor: "mme-alerts" },

    { label: "MPE Alerts", anchor: "mpe-alerts" },
    { label: "Opioid - Benzo Overlap", anchor: "opioid-benzo-overlap" },
    { label: "Inter/Intra State Queries", anchor: "inter-intra-state-queries" },
    {
      label: "Dispense Breakdown Monthly",
      anchor: "dispense-breakdown-monthly",
    },
    { label: "Dispense Breakdown Yearly", anchor: "dispense-breakdown-yearly" },
    { label: "Data Metrics", anchor: "data-metrics" },
    { label: "Naloxone Dispenses", anchor: "naloxone-dispenses" },
    { label: "Top Drug class breakdown", anchor: "top-drug-class-breakdown" },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const newPath = tabs[newValue].anchor;
    window.location.hash = newPath;
  };

  useEffect(() => {
    const anchor = window.location.hash;
    if (anchor) {
      const selectedTab = anchor.substring(1); // Remove the '#' symbol
      const tabIndex = tabs.findIndex((tab) => tab.anchor === selectedTab);
      if (tabIndex !== -1) {
        setValue(tabIndex);
      }
    }
  }, [window.location.hash]);

  return (
    <div className="login-page">
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", height: "auto" }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            border: 1,
            borderColor: "divider",
            borderRadius: "50px",
            boxShadow: "0px 1px 5px #aaaaaa",
            [`& .${tabsClasses.scrollButtons}`]: {
              boxShadow: "1px 10px 20px #aaaaaa",

              "&.Mui-disabled": { display: "none" },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              sx={{
                borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                "&.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                },
                "&:not(.Mui-selected)": {
                  "&:hover": {
                    backgroundColor: "primary.light",
                    opacity: 1,
                  },
                },
              }}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>

        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.anchor === "registered-dispensers" && <RegisteredDispensers />}
            {tab.anchor === "provisioned-users" && <ProvisionedUsers />}
            {tab.anchor === "user-queries" && <UserQueries />}
            {tab.anchor === "mme-alerts" && <MmeAlerts />}

            {tab.anchor === "mpe-alerts" && <MpeAlerts />}
            {tab.anchor === "opioid-benzo-overlap" && <Opioid />}
            {tab.anchor === "inter-intra-state-queries" && <StateQueries />}
            {tab.anchor === "dispense-breakdown-monthly" && (
              <DispenseBreakdown />
            )}
            {tab.anchor === "dispense-breakdown-yearly" && (
              <DispenseBreakdownYearly />
            )}
            {tab.anchor === "data-metrics" && <DataMetric />}
            {tab.anchor === "naloxone-dispenses" && <NaloxoneDispenses />}
            {tab.anchor === "top-drug-class-breakdown" && <TopDrugClass />}
          </TabPanel>
        ))}
      </Box>
    </div>
  );
};

export default PdmpStats;
