import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useGetSetData } from "../../services/useGetSetHook";

const Opioid = () => {
  const {
    filteredData,
    datePickersComponent,
    disclaimerComponent
  } = useGetSetData(
    process.env.REACT_APP_BASE_API + "/opiod_benzo_overlap",
    false
  );

  const chartOptions = {
    chart: {
      type: "line",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "Opioid/Benzodiazepine Overlap Alert – Percent of User Queries",
    },
    xAxis: {
      title: {
        text: "Percent of Queries Generating an Alert",
      },
      categories: filteredData.map((data) => format(data.Date, "MMM-yy")),
      reversed: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Percent of User Queries Generating an Alert",
      },
      stackLabels: {
        enabled: true,
      },
      labels: {
        formatter: function () {
          return this.value + "%";
        },
      },
    },

    series: [
      {
        name: "Percent Queried",
        data: filteredData.map((data) =>
          parseFloat(data.op_ben_overlap_percent_queried)
        ),
        dataLabels: {
          enabled: true,
          format: "{y:.1f}%",
        },
      },
    ],
    tooltip: {
      formatter: function () {
        return `<span style="font-size: 10px">${this.x}</span><br/>
                  <span style="color:${this.color}">\u25CF</span> ${
          this.series.name
        }: <b>${this.y.toFixed(2)}%</b>`;
      },
    },
  };

  const tableConfigs = [
    {
      title: "MPE Alerts",
      columns: [
        "Month",
        "Number of Alerts",
        "Unique Patients",
        "Total Number of Daily Unique Queries",
        "Triggered Alert Percentage",
      ],
      dataKeys: [
        "Date",
        "op_ben_overlap_alert",
        "op_ben_overlap_unique_patients",
        "op_ben_overlap_queries",
        "op_ben_overlap_percent_queried",
      ],
    },
  ];

  const renderTable = (config) => (
    <Grid item xs={12}>
      <TableContainer component={Paper} sx={{border: ".5px solid lightgrey"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {config.columns.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data, index) => (
              <TableRow key={index}>
                {config.dataKeys.map((key, index) => (
                  <TableCell key={index}>
                    {" "}
                    {key === "Date" ? format(data[key], "MMM-yy") : data[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
          {datePickersComponent}
        </Grid>
        <Grid item lg={1}>
          {" "}
          {disclaimerComponent("opioidBenzoOverlap")}
        </Grid>
      </Grid>
      <Paper style={{ marginBottom: "15px", padding: "10px" }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Paper>

      <Grid container spacing={2}>
        {tableConfigs.map((config, index) => (
          <React.Fragment key={index}>{renderTable(config)}</React.Fragment>
        ))}
      </Grid>
    </>
  );
};

export default Opioid;
