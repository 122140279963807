/* eslint-disable */
import React from "react";
import Grid from "@mui/material/Grid";
import Navbar from "./Navbar";
// import { useMediaQuery } from 'react-responsive'
// import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";



const styles = {
    content: {
      flexGrow: 1,
      padding: "24px",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 16px",
      height: "64px",
    },
    welcomeBlock: {
      width: "500px",
      height: "100px",
      padding: "50px",
    },
    root: {
      display: "flex",
      height: "100%",
      backgroundColor: "rgb(255,255,255)"
    
    },
    footerGrid: {
      position: "relative",
    },
  };

export const Pages = (props) => {

//   const isMobileDevice = useMediaQuery({
//     query: "(max-device-width: 480px)",
//   });
  
//   const isTabletDevice = useMediaQuery({
//     query: "(max-device-width: 821px)",
//   });
  
//   const isLaptop = useMediaQuery({
//     query: "(min-device-width: 1024px)",
//   });
  
//   const isDesktop = useMediaQuery({
//     query: "(min-device-width: 1200px)",
//   });
  
//   const isBigScreen = useMediaQuery({
//     query: "(min-device-width: 1201px )",
//   });
  return (
    <div style={styles.root}>
      {/* {isMobileDevice || isTabletDevice ? <SwipeableEdgeDrawer/> : <Navbar /> } */}
      <Navbar />
      <Grid container spacing={2}>
        <Grid item marginTop={10} xs={10} sm={10} md={11.5} lg={11.5} xl={11.5}>
          {props.children}
        </Grid>
        {/* <Grid item xs={12} style={styles.footerGrid}></Grid> */}
      </Grid>
    </div>
  );
};

export default Pages;
