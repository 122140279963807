import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { format } from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCStock from "highcharts/modules/stock";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { useGetSetData } from "../../services/useGetSetHook";
// import { PDFDocument, rgb } from 'pdf-lib';
// import html2canvas from 'html2canvas';

HCStock(Highcharts);

const TopDrugClass = () => {
  const { filteredData, datePickersComponent, disclaimerComponent } =
    useGetSetData(
      process.env.REACT_APP_BASE_API + "/drug_class_breakdown",
      false
    );
  function sortDrugsByAverageAcrossMonths(dataArray) {
    // Step 1: Calculate averages for each drug type across all months
    const drugSums = {};
    const drugCounts = {};

    // Sum up the values for each drug across all objects (months)
    dataArray.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (key.startsWith("dc_") || key.startsWith("up_")) {
          if (!drugSums[key]) {
            drugSums[key] = 0;
            drugCounts[key] = 0;
          }
          drugSums[key] += parseFloat(obj[key]);
          drugCounts[key] += 1;
        }
      });
    });

    // Calculate average for each drug
    const drugAverages = {};
    Object.keys(drugSums).forEach((drug) => {
      drugAverages[drug] = drugSums[drug] / drugCounts[drug];
    });

    // Step 2: Sort drugs inside each object based on averages in descending order
    return dataArray.map((obj) => {
      const { Date, last_date_run, id } = obj; // Preserve Date, last_date_run, and id
      const dcDrugs = {};
      const upDrugs = {};

      // Separate dc_ and up_ drugs into separate objects
      Object.keys(obj).forEach((key) => {
        if (key.startsWith("dc_")) {
          dcDrugs[key] = obj[key];
        } else if (key.startsWith("up_")) {
          upDrugs[key] = obj[key];
        }
      });

      // Sort dc_ drugs by their average values in descending order
      const sortedDcDrugs = Object.entries(dcDrugs)
        .sort((a, b) => drugAverages[b[0]] - drugAverages[a[0]])
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      // Sort up_ drugs by their average values in descending order
      const sortedUpDrugs = Object.entries(upDrugs)
        .sort((a, b) => drugAverages[b[0]] - drugAverages[a[0]])
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      // Step 3: Rebuild each object with sorted dc_ drugs first, then id, and then up_ drugs
      return {
        Date,
        last_date_run,
        ...sortedDcDrugs,
        id,
        ...sortedUpDrugs,
      };
    });
  }
  const sortedData = sortDrugsByAverageAcrossMonths(filteredData);
  
  const drugClassMapping = {
    up_dc_viralvaccine_rx: "*Viral Vaccines**",
    up_dc_statin_rx: "*HMG CoA Reductase Inhibitors**",
    up_dc_ssris_rx: "*Selective Serotonin Reuptake Inhibitors (SSRIs)**",
    up_dc_ppi_rx: "*Proton Pump Inhibitors**",
    up_dc_thyroidhormone_rx: "*Thyroid Hormones**",
    up_dc_anticonvulstantmisc_rx: "*Anticonvulsants - Misc.**",
    up_dc_betablocker_cs_rx: "*Beta Blockers Cardio-Selective**",
    up_dc_nsaid_rx: "*Nonsteroidal Anti-inflammatory Agents (NSAIDs)**",
    up_dc_acei_rx: "*ACE Inhibitors**",
    up_dc_ccb_rx: "*Calcium Channel Blockers**",
    up_dc_sympathomimetics_rx: "*Sympathomimetics**",
    up_dc_arb_rx: "*Angiotensin II Receptor Antagonists**",
    up_dc_biguanides_rx: "*Biguanides**",
    up_dc_glucocorticosteroids_rx: "*Glucocorticosteroids**",
    up_dc_bzd_rx: "*Benzodiazepines**",
    up_dc_opioidagonist_rx: "*Opioid Agonists**",
    up_dc_combocontraceptives_rx: "*Combination Contraceptives - Oral**",
    up_dc_opioidcombo_rx: "*Opioid Combinations**",
    up_dc_cenmusclerelaxants_rx: "*Central Muscle Relaxants**",
    up_dc_aminopenicillins_rx: "*Aminopenicillins**",
    up_dc_corticosteroidstop_rx: "*Corticosteroids - Topical**",
    up_dc_penicillincombo_rx: "*Penicillin Combinations**",
    up_dc_tetracyclines_rx: "*Tetracyclines**",
    up_dc_setrons_rx: "*5-HT3 Receptor Antagonists**",
    up_dc_azithromycin_rx: "*Azithromycin**",
    up_dc_cephalosporins1stgen_rx: "*Cephalosporins - 1st Generation**",
  };

  const drugClassMapping2 = {
    dc_viralvaccine_rx: "*Viral Vaccines**",
    dc_statin_rx: "*HMG CoA Reductase Inhibitors**",
    dc_ssris_rx: "*Selective Serotonin Reuptake Inhibitors (SSRIs)**",
    dc_ppi_rx: "*Proton Pump Inhibitors**",
    dc_thyroidhormone_rx: "*Thyroid Hormones**",
    dc_anticonvulstantmisc_rx: "*Anticonvulsants - Misc.**",
    dc_betablocker_cs_rx: "*Beta Blockers Cardio-Selective**",
    dc_acei_rx: "*ACE Inhibitors**",
    dc_ccb_rx: "*Calcium Channel Blockers**",
    dc_nsaid_rx: "*Nonsteroidal Anti-inflammatory Agents (NSAIDs)**",
    dc_sympathomimetics_rx: "*Sympathomimetics**",
    dc_biguanides_rx: "*Biguanides**",
    dc_opioidagonist_rx: "*Opioid Agonists**",
    dc_arb_rx: "*Angiotensin II Receptor Antagonists**",
    dc_snris_rx: "*Serotonin-Norepinephrine Reuptake Inhibitors (SNRIs)**",
    dc_glucocorticosteroids_rx: "*Glucocorticosteroids**",
    dc_opioidcombo_rx: "*Opioid Combinations**",
    dc_bzd_rx: "*Benzodiazepines**",
    dc_combocontraceptives_rx: "*Combination Contraceptives - Oral**",
    dc_loopdiuretics_rx: "*Loop Diuretics**",
    dc_amphetamines_rx: "*Amphetamines**",
    dc_tzddiuretics_rx: "*Thiazides and Thiazide-Like Diuretics**",
    dc_cenmusclerelaxants_rx: "*Central Muscle Relaxants**",
    dc_insulin_rx: "*Insulin**",
    dc_antihypertensivecombo_rx: "*Antihypertensive Combinations**",
    dc_leukotrienemodulators_rx: "*Leukotriene Modulators**",
  };

  const columns = Object.values(
    sortedData.map((x) => format(x.Date, "MMM-yy"))
  );
  let categories = [];
  if (sortedData && sortedData.length > 0) {
    const firstDataItem = sortedData[0];
    if (firstDataItem && "Date" in firstDataItem) {
      categories = Object.keys(firstDataItem).filter(function (key) {
        return key !== "Date" && key.includes("up_") && key !== "id";
      });
    }
  }
  let categories2 = [];
  if (sortedData && sortedData.length > 0) {
    const firstDataItem = sortedData[0];
    if (firstDataItem && "Date" in firstDataItem) {
      categories2 = Object.keys(firstDataItem).filter(function (key) {
        return (
          key !== "Date" &&
          !key.includes("up_") &&
          key !== "last_date_run" &&
          key !== "id"
        );
      });
    }
  }
  const seriesData = [];
  sortedData.forEach(function (monthData) {
    const month = format(monthData.Date, "MMM-yy");
    const dataValues = categories.map(function (category) {
      return parseInt(monthData[category].replace(",", ""));
    });
    seriesData.push({
      name: month,
      data: dataValues,
    });
  });

  const seriesData2 = [];
  sortedData.forEach(function (monthData) {
    const month = format(monthData.Date, "MMM-yy");
    const dataValues = categories2.map(function (category) {
      return parseInt(monthData[category].replace(",", ""));
    });
    seriesData2.push({
      name: month,
      data: dataValues,
    });
  });

  // const handleExportChart = (option) => {
  //   if (option === 'downloadPDF') {
  //     const chartContainer = chartRef.current.container.current;

  //     // Export the chart container as an image using html2canvas
  //     html2canvas(chartContainer).then((canvas) => {
  //       const chartImage = canvas.toDataURL('image/png');

  //       // Load the logo image
  //       const logoUrl = process.env.PUBLIC_URL + 'CHLogo.png'; // Replace with your logo image URL
  //       const logoImage = new Image();
  //       logoImage.src = logoUrl;

  //       logoImage.onload = () => {
  //         // Create a new canvas to combine the chart and logo images
  //         const combinedCanvas = document.createElement('canvas');
  //         const context = combinedCanvas.getContext('2d');

  //         // Set canvas dimensions to match the chart image
  //         combinedCanvas.width = canvas.width;
  //         combinedCanvas.height = canvas.height;

  //         // Draw the chart image onto the combined canvas
  //         context.drawImage(canvas, 0, 0);

  //         // Draw the logo image onto the combined canvas
  //         context.drawImage(logoImage, 10, 30, 30, 60);

  //         // Convert the combined canvas to a data URL
  //         const combinedImage = combinedCanvas.toDataURL('image/png');

  //         // Trigger download of the combined image
  //         const link = document.createElement('a');
  //         link.href = combinedImage;
  //         link.download = 'chart-with-logo.png';
  //         link.click();
  //       };
  //     });
  //   }
  // };
  const options = {
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
            // {
            //   text: 'Download as PDF',
            //   onclick: () => handleExportChart('downloadPDF'),
            // },
          ],
        },
      },
    },
    chart: {
      height: "600px",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      type: "bar",
      zoomType: "x",
      panning: true, // Enable panning
      panKey: "shift", // Use the shift key for panning
      events: {
        load: function () {
          // On chart load, set the extremes to force the scrollbar to the top
          this.xAxis[0].setExtremes(0, 6); // Adjust 10 based on how many categories you want initially visible
        },
      },
    },
    title: {
      text: "Top Drug Classes by Unique Patient Dispenses",
    },
    xAxis: {
      categories: categories.map((cate) => drugClassMapping[cate]),
      min: 20,
      scrollbar: {
        enabled: true,
        showFull: false,
      },
    },
    yAxis: {
      title: {
        text: "Dispenses",
      },
      min: 0,
    },
    series: seriesData,
  };

  const options2 = {
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            // "separator",
            // "printChart",
            // "downloadPDF",
          ],
        },
      },
    },
    chart: {
      height: "600px",
      type: "bar",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      zoomType: "x", // Enable zooming on the x-axis
      panning: true, // Enable panning
      panKey: "shift",
      events: {
        load: function () {
          // On chart load, set the extremes to force the scrollbar to the top
          this.xAxis[0].setExtremes(0, 6); // Adjust 10 based on how many categories you want initially visible
        },
      },
    },
    title: {
      text: "Top Drug Classes by Dispenses",
    },
    xAxis: {
      categories: categories2.map((cate) => drugClassMapping2[cate]),
      min: 20,

      scrollbar: {
        enabled: true,
        showFull: false,
      },
    },
    yAxis: {
      title: {
        text: "Dispenses",
      },
    },
    series: seriesData2,
  };

  return (
    <>
      <Grid container spacing={10}>
        <Grid item lg={9}>
          {" "}
          {datePickersComponent}
        </Grid>
        <Grid item lg={1}>
          {disclaimerComponent("topDrugClassBreakdown")}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box mb={2}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                      // ref={chartRef}
                    />
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box mb={2}>
                <Paper elevation={1}>
                  <Box p={1}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options2}
                    />
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Box mb={1}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Typography variant="h6">
                  Top Drug Classes by Unique Patients Dispenses - Tabular data
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer
                  component={Paper}
                  style={{ width: "100%", border: ".5px solid lightgrey" }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Drug Class</TableCell>{" "}
                        {/* First column heading */}
                        {columns.map((column) => (
                          <TableCell key={column}>{column}</TableCell> // Render column headings
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.length > 0 &&
                        Object.keys(sortedData[0]).map((metric) => {
                          if (metric === "Date" || !metric.includes("up_")) {
                            return null;
                          }
                          const drugClassName = drugClassMapping[metric] || "";
                          return (
                            <TableRow key={metric}>
                              <TableCell>
                                <Typography>{drugClassName}</Typography>{" "}
                                {/* Metric name in the first column */}
                              </TableCell>
                              {sortedData.map((item) => (
                                <TableCell key={item.Date}>
                                  {item[metric]}
                                </TableCell> // Render values for each metric
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Box mb={2}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                <Typography variant="h6">
                  Top Drug Classes by Dispenses - Tabular data
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer
                  component={Paper}
                  style={{ width: "100%", border: ".5px solid lightgrey" }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Drug Class</TableCell>{" "}
                        {/* First column heading */}
                        {columns.map((column) => (
                          <TableCell key={column}>{column}</TableCell> // Render column headings
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData.length > 0 &&
                        Object.keys(sortedData[0]).map((metric) => {
                          if (
                            metric === "Date" ||
                            metric.includes("up_") ||
                            metric === "last_date_run" ||
                            metric === "id"
                          ) {
                            return null;
                          }
                          const drugClassName2 =
                            drugClassMapping2[metric] || "";
                          return (
                            <TableRow key={metric}>
                              <TableCell>
                                <Typography>{drugClassName2}</Typography>{" "}
                                {/* Metric name in the first column */}
                              </TableCell>

                              {sortedData.map((item) => (
                                <TableCell key={item.Date}>
                                  {item[metric]}
                                </TableCell> // Render values for each metric
                              ))}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TopDrugClass;
